//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '@/services/i18n.init';

const ENUM_OPTIONS = [
  { label: i18n.t('events.my.filter.date_all'), value: null },
  { label: i18n.t('events.my.filter.date_today'), value: 'TODAY' },
  { label: i18n.t('events.my.filter.date_tomorrow'), value: 'TOMORROW' },
  { label: i18n.t('events.my.filter.date_choose_date'), value: 'CHOOSE_DATE' },
];

export default {
  name: 'DateEnumFilter',
  props: ['prefill', 'prefillDate', 'locale'],
  components: {},
  data() {
    return {
      dateSpan: null,
      dateSelected: null,
      dateSpanOptions: ENUM_OPTIONS,
    };
  },
  methods: {
    emit() {
      this.$emit('dateSelected', this.dateSelected);
    },
  },
  mounted() {
    this.dateSpan = this.prefill;
    this.dateSelected = this.prefillDate;
  },
  created() {},
  watch: {
    dateSpan(newval) {
      this.$emit('dateChange', newval);
      if (newval !== 'CHOOSE_DATE') this.dateSelected = null;
    },
    dateSelected(newval) {
      this.$emit('dateSelected', newval);
    },
    prefill(newval) {
      this.dateSpan = newval;
    },
    prefillDate(newval) {
      this.dateSelected = newval;
    },
  },
};
